<template>
    <div class="collapsible-content" v-bind:style="style" v-if="wrap">
        <div>
            <Button
                class="collapsible-content_header" 
                :id="'collapse-content-button' + collapseId + collapseRndId" 
                variant="link" 
                :class="collapsibleClass"
                data-toggle="collapse"
                :data-target="'#collapse-content-light' + collapseId + collapseRndId"
                :aria-expanded="this.collapseShow"
                :aria-controls="'collapse-content-light' + collapseId + collapseRndId"
                :border-radius="borderRadius"
            >   
                <div class="header-title">
                    {{ title }}
                </div>
                <font-awesome-icon class="header-icon" icon="caret-right"/>
            </Button>
            <div class="collapse"
                :id="'collapse-content-light' + collapseId + collapseRndId" v-bind:visible="isOpen || isCurrentOpen"
            >
                <div class="collapsible-content-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <slot></slot>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import Button from "@/components/Buttons/Button";

library.add( faCaretRight )

export default {
    components: {
        Button
    },
    data() {
        return {
            style: {
                '--button_bg_color': this.buttonBgColor,
                '--button_padding': this.buttonPadding,
                '--content_padding': this.contentPadding,
                '--content_background': this.contentBackground
            },
            isCurrentOpen: false,
            show: false,
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        collapseId: {
            type: [ String, Number ],
            default: Math.floor( Math.random() * 1000 )
        },
        collapseRndId: {
            type: [ String, Number ],
            default: Math.floor( Math.random() * 1000 )
        },
        buttonBgColor: {
            type: String,
            default: 'transparent'
        },
        buttonPadding: {
            type: String,
            default: '15px 0'
        },
        contentPadding: {
            type: String,
            default: '0px'
        },
        contentBackground: {
            type: String,
            default: 'transparent'
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        toggleOpenItem:{
            type: Function,
            default: ( id, context ) => {
                context.isCurrentOpen = !context.isCurrentOpen;
            }
        },
        wrap:{
            type: Boolean,
            default: true
        },
        borderRadius: {
            type: String,
            default: "5px"
        },
    },
    computed:{
        collapsibleClass(){
            if( this.isOpen || this.isCurrentOpen ) return 'not-collapsed';

            return 'collapsed';
        },
        collapseShow: {
            get() {
                return this.show;
            },
            set(val) {
                this.show = val;
            }
        }
    },
    mounted() {
        this.collapseShow = this.isOpen;
    },
    watch: {
        isOpen(val) {
            this.collapseShow = val;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.collapsible-content {

    &_header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        align-items: center;
        transition: all .3s ease;
        padding:  var(--button_padding);
        background-color: var(--button_bg_color);
        font-family: 'Lato';
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 200%;
        color: $light_text;

        &:focus,
        &:hover {
            outline: none;
            box-shadow: none;
            text-decoration: none;
        }

        &:hover {
            color: $navy;
        }

        .header-icon {
            font-size: 16px;
            transition: all .3s ease;
        }

        &.not-collapsed {
            color: $navy;

            .header-icon {
                transform: rotate(90deg);
            }
        }
    }

    &-body {
        padding: var(--content_padding);
        background: var(--content_background);
    }
}
</style>