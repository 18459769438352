<template functional>
	<div class="block-wrapper"
		v-on:dragstart="props.onDragging"
		v-on:dragend="props.dragEnd"
		v-bind:draggable="true"
		v-bind:transfer-id="props.dataComponent"
		v-on:click="props.click"
	>
		<div class="block-item">
			<div class="block-item__square">
				<font-awesome-icon v-if="!props.icon || !props.icon.length" icon="network-wired"></font-awesome-icon>
				<img v-else loading="lazy" :src="props.icon" alt="Block icon" />
			</div>
			<div class="block-item__title">{{ props.label }}</div>
		</div>
	</div>
</template>

<script>

	import { library } from '@fortawesome/fontawesome-svg-core';
	import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';

	library.add( faNetworkWired );
	export default {
		name: "BlockItem",
		props: {
			dataObject: {
				type: Object,
			},
			icon: {
				type: String,
				default: ''
			},
			label: {
				type: String,
			},
			onDragging: {
				type: Function,
			},
			dragEnd: {
				type: Function,
			},
			dataComponent: {
				type: String
			},
			click: {
				type: Function,
				default: () => {}
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/styles/variables';

	.block-wrapper.core-block{
		.block-item{
			width:100%;
		}
	}
	.block-item {
		width: 90px;
		pointer-events: none;

		&__square {
			position: relative;
			height: 90px;
			background-color: $background;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				font-size: 30px;
				color: $light_text;
				transform: translate(-50%, -50%);
			}

			img
			{
				width: 50px;
				height: 50px;
				object-fit: contain;
				object-position: center;
			}
		}

		&__title {
			font-family: 'Lato';
			font-weight: normal;
			font-size: 0.875rem;
			line-height: 200%;
			color: $dark_2;
			margin: 10px 0 15px;
		}
	}

	.jumbotron.base-card {
		display: none;

		.advanced-block {

			&__body {
				.item {
					border-bottom: 1px solid $light_gray;
					margin-bottom: 10px;
					padding-bottom: 10px;

					&:last-child {
						border: none;
					}
				}
			}

			&__footer {
				border-top: 1px solid $light_gray;
				text-align: right;
				padding: 15px 30px 0;
				margin: 15px -30px 0;
				font-size: 14px;
				line-height: 24px;
			}
		}
	}

</style>